import { Vue } from 'vue-property-decorator';

export type TabSolver = (this: Vue) => boolean;
export type OnTabLeave<T = any> = (this: T) => boolean | Promise<boolean>;

export abstract class SettingsTab extends Vue {
    static title = 'unnamed tab';

    static adminOnly = false;

    static isFullwidth: boolean = false;

    static disabled: boolean = false;

    /**
     * Method that desides to show to user this tab or not
     * Pass `null` to show tab automatically
     *
     * @default null
     */
    static showSolver: TabSolver | null = null;

    /**
     * Method that fires when user changes tab. If it
     * returns true the tab will be changed otherwise will not
     *
     * @default null
     */
    static onTabLeave: OnTabLeave | null = null;
}
