
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class CustomTooltip extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    })
    active!: boolean;

    @Prop({
        type: String,
        default: '376px',
    })
    width!: number;

    @Prop({
        type: String,
        default: null,
    })
    tooltipClass!: string;

    @Prop({
        type: String,
        default: 'right',
    })
    side!: 'left' | 'right';

    @Prop({
        type: Number,
        default: 19,
    })
    verticalOffset!: number;

    getContainer() {
        return this.$refs.container as unknown as HTMLDivElement;
    }
}
