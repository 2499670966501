
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ColorPicker } from 'element-ui';

@Component({
    components: {
        ColorPicker,
    },
})
export default class ColorPalettePicker extends Vue {
    @Prop({
        type: String,
        default: null,
    })
    value!: string | null;

    @Prop({
        type: Boolean,
        default: false,
    })
    showColorString!: boolean;

    @Prop({
        type: [String, Number],
    })
    id!: string | number;

    @Prop({
        type: Boolean,
        default: false,
    })
    isClearBtnHidden!: boolean;

    private isDropdownShown = false;

    public get popperClass() {
        return Object
            .entries({
                'color-palette-picker__dropdown': true,
                'color-palette-picker__dropdown--hidden-clear-btn': this.isClearBtnHidden,
            })
            .reduce((acc, [className, condition]) => {
                if (!condition) return acc;
                return [...acc, className];
            }, [] as string[]).join(' ');
    }

    handleChange(e: string | number) {
        this.$emit('change', [this.id, e]);
    }

    handleClick(e: MouseEvent & { target: HTMLElement }) {
        if (!e.target || !!e.target.closest('div.el-color-picker') || this.isDropdownShown) {
            this.isDropdownShown = !this.isDropdownShown;
            return;
        }

        const ref = this.$refs['color-picker'] as Vue;

        if (!ref) {
            return;
        }

        const triggerElement = ref.$el.querySelector('.el-color-picker__trigger') as HTMLElement;

        if (!triggerElement) {
            return;
        }

        triggerElement.click();
    }
}
