
import { Component, Vue, Prop } from 'vue-property-decorator';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import Item from '@/modules/common/interfaces/item.interface';
import CustomTooltip from '@/modules/common/components/ui-kit/custom-tooltip.vue';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';

/**
 * Is used in default-filters.tab
 * Represents default filter row
 */
@Component({
    components: {
        CustomSelect,
        CustomTooltip,
        CustomMultiSelect,
    },
})
export default class SelectSection extends Vue {
    @Prop({ type: String })
    title!: string;

    @Prop({ type: String })
    label!: string;

    @Prop({ type: Array })
    items!: Item[];

    @Prop({ type: [Number, String, Array] })
    value!: string | number | string[] | number[];

    @Prop({ type: String })
    hint!: string;

    @Prop({ type: Boolean })
    multiselect!: boolean;

    get v() {
        return this.value;
    }

    set v(v: string | number | string[] | number[]) {
        this.$emit('input', v);
    }
}
