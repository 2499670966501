
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ExpansionPanel extends Vue {
    @Prop({ type: Boolean })
    disabled!: boolean;

    @Prop({ type: [String, Number] })
    expandHeight!: string | number;

    @Prop({ type: Boolean })
    expanded!: boolean;

    isExpanded = this.expanded;
    isContentVisible = false;

    public mounted() {
        this.recalculateHeightExpanded();

        window.addEventListener('resize', this.recalculateHeightExpanded);
    }

    public beforeDestroy() {
        window.removeEventListener('resize', this.recalculateHeightExpanded);
    }

    private recalculateHeightExpanded() {
        const { body } = this.$refs as { body: HTMLDivElement };

        if (!body) return;

        body.style.transition = '0s';
        body.style.height = '0';
        body.style.height = `${this.expandHeight || body.scrollHeight}px`;
        body.style.transition = '';
    }

    public toggleExpand() {
        this.isExpanded = !this.isExpanded;
    }
}
