
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class Dialog extends Vue {
    @Prop({ type: [Object, Boolean, String, Number], default: false })
    value!: boolean;

    @Prop({ type: Boolean, default: false })
    byVShow!: boolean;

    @Prop({ type: [Number, String], default: '' })
    width!: number | string;

    @Prop({ type: Boolean, default: false })
    pending!: boolean;

    close() {
        this.$emit('input', false);
        this.$emit('close');
    }

    closeByOverlay(e: MouseEvent) {
        const { container } = this.$refs as { [k: string]: HTMLElement };

        if (e.target !== container) return;

        this.close();
    }
}
