var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
        'custom-tooltip': true,
        'custom-tooltip--active': _vm.active,
        ['custom-tooltip--' + _vm.side]: true,
    },style:({
        '--tooltip-width': _vm.width,
        '--tooltip-vertical-offset': _vm.verticalOffset + 'px',
    })},[(_vm.$slots.hoverContent)?_c('div',{staticClass:"custom-tooltip__hover-trigger"},[_vm._t("hoverContent")],2):_vm._e(),_c('div',{ref:"container",class:{
            'custom-tooltip__container': true,
            [_vm.tooltipClass]: true,
        }},[(_vm.$slots.headline)?_c('div',{staticClass:"custom-tooltip__headline"},[_vm._t("headline")],2):_vm._e(),_c('div',{staticClass:"custom-tooltip__content"},[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }