
import { Component } from 'vue-property-decorator';
import { inject } from '@/inversify';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import ChartColors from '../sections/chart-colors.vue';
import { SettingsTab } from '../../interfaces/settings-tab.abstract';

@Component({
    components: {
        ChartColors,
    },
})
export default class ScanPropertiesTab extends SettingsTab {
    @inject(UserSettingsS) private userSettingsService!: UserSettingsService;

    static title = 'settings.colors.title';

    get colors() {
        return this.userSettingsService.chartColors;
    }
}
